import React, { useEffect, useRef } from "react";

// Biblioteca
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ModalImage = ({
  showImageModal,
  setShowImageModal,
  productSelecting,
}) => {
  // Ativa modal desativa o scroll
  useEffect(() => {
    // Aperta esc saí do modal
    const handleKeyPress = (e) => {
      if (e.keyCode === 27) {
        setShowImageModal(false);
      }
    };

    if (showImageModal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyPress);
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [showImageModal]);

  if (showImageModal) {
    return (
      <TransformWrapper
        options={{
          disableRotation: true,
          pan: false,
        }}
      >
        {({ zoomIn, zoomOut }) => (
          <React.Fragment>
            <div className="transition-all ease-in-out duration-300 hover:scale-105 fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 z-50 flex flex-col justify-center items-center w-full">
              <div className="relative m-4 sm:w-full md:w-3/4 lg:w-1/2  rounded-lg bg-white font-sans text-base font-light leading-relaxed text-blue-gray-500 antialiased shadow-2xl">
                <div className="border-gray-400 flex shrink-0 items-center justify-between p-4 font-sans text-2xl font-semibold leading-snug text-blue-gray-900 antialiased">
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => zoomIn()}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-sm px-4 py-2.5 mr-1 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      +
                    </button>
                    <button
                      onClick={() => zoomOut()}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-sm px-[1.3em] py-2.5 mr-3 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      -
                    </button>
                  </div>

                  <button
                    onClick={() => setShowImageModal(false)}
                    type="button"
                    className="mb-2 text-gray-400 bg-transparent hover:bg-blue-200 hover:text-blue-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-blue-600 dark:hover:text-white"
                    data-modal-hide="default-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="bg-white rounded-lg w-full overflow-auto max-w-[800px] p-4 flex items-center justify-center">
                  <TransformComponent>
                    <div className="w-full flex items-center justify-center pl-[8em]">
                      <img
                        alt={productSelecting[1]}
                        className="sm:h-auto w-full object-cover object-center"
                        src={productSelecting[0]}
                      />
                    </div>
                  </TransformComponent>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  }
};

export default ModalImage;
