// Blibiotecas
import { useRecoilState } from "recoil";

// Atoms
import { cartAtom } from "../atoms/cartAtom";

// Utils
import { ProdutosDate } from "./ProductDatabase";

import React from "react";

export const Cartfunction = (product) => {
  const [cartState, setCartState] = useRecoilState(cartAtom);

  const addCart = (product) => {
    setCartState((cartState) => {
      const productId = cartState.findIndex((item) => item._id === product._id);

      if (productId === -1) {
        return [
          ...cartState,
          { ...product, quantity: 1, amount: product.value },
        ];
      } else {
        // SE não atualiza so a quantidade

        const quantityInCart = [...cartState];
        quantityInCart[productId] = {
          ...quantityInCart[productId],
          quantity: quantityInCart[productId].quantity + 1,
          amount: (quantityInCart[productId].quantity + 1) * product.value,
        };
        return quantityInCart;
      }
    });
  };

  const handleQuantity = (product, quantity) => {
    setCartState((cartState) => {
      const productId = cartState.findIndex((item) => item._id === product._id);
      if (productId !== -1) {
        const quantityInCart = [...cartState];
        quantityInCart[productId] = {
          ...quantityInCart[productId],
          quantity: quantity,
          amount: quantity * product.value,
        };
        return quantityInCart;
      }
    });
  };

  const handleRemoveCart = (product) => {
    setCartState((cartState) =>
      cartState.filter((item) => item._id !== product._id)
    );
  };

  let finalvalue = 0;
  for (const produto of cartState) {
    finalvalue += produto.value * produto.quantity;
  }

  return { addCart, finalvalue, handleQuantity, handleRemoveCart };
};
