// React
import { useEffect, useState } from "react";

// Dados
import Settings from "../config/Settings.json";

// Componentes
import CatalogMenu from "../components/CatalogMenu";
import { CatalogBanner } from "../components/CatalogBanner";
import FloatingIcons from "../components/FloatingIcons";
import ProductsCatalog from "../components/ProductsCatalog";
import { getSubdomain } from "../utils/utils.common";

export const Catalog = () => {
  const [catalogSettings, setCatalogSettings] = useState([]);

  // Função De Carregar Css de cada marca
  useEffect(() => {
    setCatalogSettings(Settings.brands);
  }, []);

  const url = window.location.hostname;
  const subDominio = getSubdomain(url);

  // console.log("subDominio", subDominio);
  const brandFound = catalogSettings.find(
    (brand) => brand.brand === subDominio
  );

  if (brandFound) {
    // Função Para substituir Icon do navegador
    // console.log("brandFound", brandFound);
    const faviconLink = document.querySelector("link[rel*='icon']");
    faviconLink.href = `${process.env.PUBLIC_URL}/${brandFound.iconCatalog}`;

    // Função para troca titulo da página de acordo com Subdominio
    document.title = brandFound.title;

    // console.log("marca", subDominio);
    return (
      <div className="w-full flex ">
        <CatalogMenu brandFound={brandFound} />
        <main className="w-full mt-[4.5em] items-center justify-center flex flex-col">
          <CatalogBanner brandFound={brandFound} />
          <div className="w-full">
            <ProductsCatalog brandFound={brandFound} brand={subDominio} />
          </div>
        </main>
        <FloatingIcons />
      </div>
    );
  } else {
    return;
    <p>Nenhuma marca encontrada!!!!!</p>;
  }

  // return (
  //   <>
  //     {config.length > 0 ? (
  //       config.map((configpeining, index) => (
  //         <div key={index}>
  //           <h1>{configpeining.title}</h1>
  //         </div>
  //       ))
  //     ) : (
  //       <p>Nenhuma marca encontrada para este subdomínio</p>
  //     )}
  //   </>
  // );
};
