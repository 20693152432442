import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { CatalagoKimaster, CatalagoPeining } from "./config/Router";
import { getApp } from "./config/getSubdomain";

// Componetes

// Imagens

function Peining() {
  const RotaAtual = getApp();

  // console.log({ RotaAtual });
  return (
    <BrowserRouter>
      <RotaAtual />
    </BrowserRouter>
  );
}

export default Peining;
