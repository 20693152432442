import React from "react";

// Imagem
import Kimaster from "./../assets/iconKimaster.png";
import Peining from "./../assets/iconPeining.png";

const LoadingData = ({ brand }) => {
  const iconbrands = {
    kimaster: Kimaster,
    peining: Peining,
  };
  // console.log("A seguinte marca foi escolhida: ", iconbrands[brand]);
  return (
    <div className="flex items-center justify-center w-full h-full rounded-lg ">
      <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
      <div className="relative flex justify-center items-center flex-col">
        <img src={iconbrands[brand]} className="rounded-full h-8 w-8 animate-pulse" />
        <p className="font-semibold text-sm">Carregando...</p>
      </div>
    </div>
  );
};

export default LoadingData;
