import React, { useMemo } from "react";

// Utilidades
import { ProductDatabase } from "./ProductDatabase";

// Atom
import { useRecoilValue } from "recoil";
import { searchaAtom } from "../atoms/searchaAtom";

export const SearchFilter = (url) => {
  const { data: products } = ProductDatabase(url);
  const search = useRecoilValue(searchaAtom);

  const FilteredProducts = useMemo(() => {
    return (
      products &&
      products.filter((product) =>
        product.description.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [products, search]);

  return { FilteredProducts };
};
