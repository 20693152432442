import { Domains } from "./Domains";

// Função para obter a aplicação com base no subdomínio
export const getApp = () => {
  // Obtém o subdomínio da URL atual
  const subdomain = getSubdomain(window.location.hostname);
  // console.log("subdomain: " + subdomain);

  // Encontra o aplicativo principal nos domínios
  const main = Domains.find((app) => app.main);
  // console.log("main : " + JSON.stringify(main, null, 2));

  if (!main) throw new Error("Dever haver alguma aplciativo principal");

  if (subdomain === "") {
    console.log("subdomain = '' " + main.app);
    return main.app;
  }

  const app = Domains.find((app) => subdomain === app.subDomain);

  if (!app) {
    // console.log("!app: " + main.app);
    return main.app;
  }

  // console.log("encontrou app");
  return app.app;
};

const getSubdomain = (location) => {
  const locationParts = location.split(".");
  console.log(locationParts[1]);

  let sliceTill = -2;

  const isLocalHost = locationParts.slice(-1)[0] === locationParts[1];
  if (isLocalHost) {
    sliceTill = -1;
  }

  return locationParts.slice(0, sliceTill).join("");

  //   return locationParts[0];
};
