// Atom
import { useRecoilValue } from "recoil";
import { cartAtom } from "../atoms/cartAtom";

// Funçãoes
import { Cartfunction } from "./Cartfunction";

// React
import { useEffect, useState } from "react";
import { sellerAtom } from "../atoms/SellerAtom";

export const FormatMessage = () => {
  const productList = useRecoilValue(cartAtom);
  const [message, setMessage] = useState("");
  const seller = useRecoilValue(sellerAtom);
 
  const { finalvalue } = Cartfunction();

  const formattedProducts = productList.map((product) => {
    const description = product.description.padEnd(45, " ");
    const value = parseFloat(product.value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    const quantity = product.quantity;

    return `${description} | Valor: ${value} - Quantidade: ${quantity}`;
  });

  const finalMessage = [
    "Olá, gostaria de conhecer os seguintes produtos:",
    ...formattedProducts,
    `Valor total: ${parseFloat(finalvalue).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    })}`,
    `Vendedor: ${seller}`
  ].join("\n");

  //   useEffect(() => {
  //     setMessage(formattedProducts(productList, finalvalue));
  //   }, [productList, finalvalue]);

  useEffect(() => {
    setMessage(finalMessage);
  }, [productList, finalvalue]);

  return { finalMessage, message };
};
