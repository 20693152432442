// Bibliotecas
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Styles
import "swiper/css";

export const CatalogBanner = ({ brandFound }) => {
  return (
    <div className="bg-gray-50 flex w-full z-100">
      <section className="bg-cover bg-center w-full ">
        <Swiper
          className="bg-cover bg-center w-full"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          // navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {brandFound.banners.map((bannerImage, index) => (
            <SwiperSlide key={index}>
              <img src={bannerImage} className="w-full" />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};
