import { Catalogs, PageAdmin } from "./Router";

export const Domains = [
  {
    subDomain: "peining",
    app: Catalogs,
    main: false,
  },
  {
    subDomain: "kimaster",
    app: Catalogs,
    main: false,
  },
  {
    subDomain: "devia",
    app: Catalogs,
    main: false,
  },
  {
    subDomain: "hrebos",
    app: Catalogs,
    main: false,
  },
  {
    subDomain: "playcell",
    app: Catalogs,
    main: false,
  },
  {
    subDomain: "admin",
    app: PageAdmin,
    main: true,
  },
];
