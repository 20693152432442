// Biblioteca
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

// Imagens
import cartIcon from "./../assets/cart.png";
import whatAppIcon from "./../assets/whatsapp.png";

// Recoil
import { useRecoilValue } from "recoil";
import { cartAtom } from "../atoms/cartAtom";

const FloatingIcons = () => {
  const cartState = useRecoilValue(cartAtom);

  return (
    <div className="relative">
      <button className="z-20 text-white flex flex-col shrink-0 grow-0 justify-around fixed bottom-0 right-0 rounded-lg mr-1 mb-5 lg:mr-2 lg:mb-5 xl:mr-10 xl:mb-10">
        <div className="pb-5">
          <Link to="/car" className="">
            <img
              src={cartIcon}
              alt="Carrinho"
              className="md:w-16 w-12 transform hover:scale-125 hover:animate-pulse"
            />
            <span className="absolute -top-3 md:left-10 left-7 rounded-full bg-red-500 p-0.5 px-2 md:text-xm text-sm text-red-50">
              {cartState.length}
            </span>
          </Link>
        </div>
        <div className="">
          <ReactWhatsapp
            className="w-full"
            number="+558592328282"
            message="Olá, gostaria de conhecer os seguintes produtos:"
          >
            <div className="">
              <img
                src={whatAppIcon}
                alt="WhatsApp"
                className="md:w-16 w-12 transform hover:scale-125 hover:animate-pulse"
              />
            </div>
          </ReactWhatsapp>
        </div>
      </button>
    </div>
  );
};

export default FloatingIcons;
