// React
import React, { useMemo, useState } from "react";

// Utilidades
import { ProductDatabase } from "../utils/ProductDatabase";
import LoadingData from "./LoadingData";
import { formatCurrency } from "../utils/utils.common";
import ModalImage from "./ModalImage";
import { SearchFilter } from "../utils/SearchFilter";
import { Cartfunction } from "../utils/Cartfunction";
import { useRecoilState, useRecoilValue } from "recoil";
import { cartAtom } from "../atoms/cartAtom";

// Atom

const ProductsCatalog = ({ brand }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [productSelecting, setProductSelecting] = useState([]);
  const [cartState, setCartState] = useRecoilState(cartAtom);

  // Função para carregar API de cada catalago
  let url = `https://api.catalogo.website/v1/api/products/catalogo?`; //brand=${brand}&limit=100
  if (brand !== "playcell") {
    url += `brand=${brand}&limit=301`;
  } else {
    url += `brand=b-max&limit=301`;
  }

  // console.log("url", url)

  // API
  const { loading } = ProductDatabase();

  // Filtro de pesquisa
  const { FilteredProducts } = SearchFilter(url);

  // Função para adicionar ao carrinho
  const { addCart, handleQuantity } = Cartfunction();

  return (
    <section className="py-2 w-full bg-gray-100 overflow-auto">
      {loading && <LoadingData brand={brand} />}

      <div className="mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {FilteredProducts &&
          FilteredProducts.map((product) => (
            <article
              key={product._id}
              className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 border border-gray-400"
            >
              <div>
                <button
                  onClick={() => {
                    setShowImageModal(true);
                    setProductSelecting([
                      product.urlImage,
                      product.description,
                    ]);
                  }}
                  className="relative flex items-end overflow-hidden rounded-xl"
                >
                  <img src={product.urlImage} />
                </button>

                <div className="mt-1 p-2 border-t border-0.2 border-gray-400">
                  <h2 className="text-black font-black">
                    {product.description}
                  </h2>
                  <p className="mt-1 text-sm text-slate-400 font-semibold">
                    ID: {product.ref}
                  </p>
                  <p className="mt-1 text-sm text-slate-400 font-semibold">
                    CX: {product.cx_und}
                  </p>
                  {/* Função para filtra os produtos selecionandos de acordo com seu ID */}
                  {productSelecting.includes(product._id) &&
                    cartState
                      .filter((cartProduct) => cartProduct._id === product._id)
                      .map((cartProduct) => (
                        <div key={product._id} className="">
                          <p className="mt-1 text-sm text-slate-400 font-semibold pb-2">
                            Quantidade:
                          </p>
                          <input
                            type="number"
                            id="quant"
                            min={0}
                            onChange={(e) => {
                              handleQuantity(product, parseInt(e.target.value));
                            }}
                            value={cartProduct.quantity}
                            className="md:text-base text-sm text-xs border border-blue w-14 text-sm rounded-lg block p-1 dark:border-blue-600 dark:placeholder-gray-400 dark:text-black font-semibold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                          {/* <label
                            className="before:content[' '] after:content[' '] 
                                    pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] 
                                    font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] 
                                    before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md 
                                    before:border-t before:border-l before:border-blue before:transition-all 
                                    after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block 
                                    after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r 
                                    after:border-blue after:transition-all peer-placeholder-shown:text-sm 
                                    peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-slate-400 
                                    font-semibold peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:
                                    border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-slate-400 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-blue peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-blue-500 peer-focus:transition-all
                                    peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                          >
                            {cartProduct.quantity}
                          </label> */}
                        </div>
                      ))}
                  {/* </p> */}

                  <div className="mt-3 flex flex-col">
                    <p className="text-lg font-bold text-black font-black">
                      {formatCurrency(product.value)}
                    </p>
                    <div
                      className={`${
                        productSelecting.includes(product._id)
                          ? "flex items-center space-x-1.5 rounded-lg px-4 py-1.5 text-white duration-100 bg-green-500 hover:bg-green-400 hover:text-black"
                          : "flex items-center space-x-1.5 rounded-lg bg-button-car px-4 py-1.5 text-white duration-100 hover:bg-blue-600"
                      }`}
                    >
                      <ion-icon
                        name={
                          productSelecting.includes(product._id)
                            ? "checkmark-outline"
                            : "cart-outline"
                        }
                      ></ion-icon>

                      <button
                        onClick={() => {
                          addCart(product);
                          // setProductSelecting(...product._id, {...productSelecting});
                          setProductSelecting((prevProducts) => [
                            ...prevProducts,
                            product._id,
                          ]);
                        }}
                        className="text-sm font-bold"
                      >
                        {productSelecting.includes(product._id)
                          ? "Adicionando ao carrinho!!"
                          : "Adicionar ao carrinho"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
        <ModalImage
          showImageModal={showImageModal}
          productSelecting={productSelecting}
          setShowImageModal={() => setShowImageModal(!showImageModal)}
        />
      </div>
    </section>
  );
};

export default ProductsCatalog;
