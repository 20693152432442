import React from "react";

// BiBliotecas
import { Route, Routes } from "react-router-dom";

// Pages
import { Catalog } from "../page/Catalog";
import { Admin } from "../page/Admin";
import Cart from "../page/Cart";

export const Catalogs = () => {
  // console.log("Catalogs");
  return (
    <Routes>
      <Route path="/" element={<Catalog />} />
      <Route path="/car" element={<Cart />} />
    </Routes>
  );
};

export const PageAdmin = () => {
  return (
    <Routes>
      <Route path="/" element={<Admin />} />
    </Routes>
  );
};
