// Biblioteca
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

// Atom
import { useRecoilState } from "recoil";
import { cartAtom } from "../atoms/cartAtom";

// Utils
import { formatCurrency } from "../utils/utils.common";
import { Cartfunction } from "../utils/Cartfunction";
import { useEffect, useState } from "react";
import { FormatMessage } from "../utils/FormatMessage";
import { sellerAtom } from "../atoms/SellerAtom";

// React

const CartCheckout = () => {
  const [cartState, setCartState] = useRecoilState(cartAtom);
  const [seller, setSeller] = useRecoilState(sellerAtom);

  // Função de altera quantidade é o total
  const { finalvalue, handleQuantity, handleRemoveCart } = Cartfunction();
  const { finalMessage } = FormatMessage();

  // console.log("Produtos no carrinho final", cartState);

  // useEffect(() => {
  //   setMessage(FormatMessage(cartState, finalvalue))
  // }, [cartState, finalvalue])

  return (
    <>
      <header className="flex justify-center items-center pt-4 w-full mb-10">
        <div className="flex items-start md:w-[20%] w-[40%] pl-3">
          <Link
            to="/"
            className="rounded-full font-semibold flex-row flex items-center transition-transform ease-in duration-300 transform hover:scale-110 hover:bg-blue-500 hover:text-white hover:shadow-md"
          >
            <ion-icon name="caret-back-outline"></ion-icon>
            <p className="p-2 md:text-sm text-xs">Voltar para catálogo</p>
          </Link>
        </div>
        <h1 className="pb-1 text-center md:text-2xl text-lg font-bold  items-start justify-center w-full md:pr-24">
          Carrinho de compras
        </h1>
      </header>

      <div className="flex align-center justify-center flex-col w-full">
        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="bg-white border-b md:text-base text-xs font-bold text-black px-2 py-4 text-left">
                  <tr>
                    <th
                      scope="col"
                      className="w-[5px] py-4 px-6 text-left text-gray-600 font-bold uppercase "
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="w-28 py-4 px-6 text-left text-gray-600 font-bold  uppercase "
                    >
                      Descrição
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 py-4 px-6 text-left text-gray-600 font-bold uppercase"
                    >
                      Quant
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 py-4 px-6 text-left text-gray-600 font-bold uppercase"
                    >
                      Valor Unit.
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 py-4 px-6 text-left text-gray-600 font-bold uppercase"
                    >
                      Valor Total
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 py-4 px-6 text-left text-gray-600 font-bold uppercase"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cartState.length === 0 ? (
                    <p className="font-semibold text-gray-500">
                      Carrinho vazio
                    </p>
                  ) : (
                    cartState.map((product) => (
                      <tr className="bg-gray-100 border-b  md:text-base text-sm text-xs text-black font-semibold">
                        <td className="py-3 px-5 border-b border-gray-100 md:whitespace-nowrap break-words">
                          {product.ref}
                        </td>
                        <td className="py-3 px-5 border-b border-gray-100 md:whitespace-nowrap break-words">
                          {product.description}
                        </td>
                        <td className="text-sm text-black-900 font-semibold px-6 py-4">
                          <input
                            type="number"
                            id="quant"
                            min={0}
                            onChange={(e) => {
                              handleQuantity(product, parseInt(e.target.value));
                            }}
                            value={product.quantity}
                            className="md:text-base text-sm text-xs border border-gray-300 w-14 text-sm rounded-lg block p-1 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black font-semibold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </td>
                        <td className="py-3 md:px-10 px-5 border-b border-gray-200">
                          {formatCurrency(product.value)}
                        </td>
                        <td className="py-3 md:px-10 px-5 border-b border-gray-200">
                          {formatCurrency(product.amount)}
                        </td>
                        <td className="py-3 md:px-10 px-5 border-b border-gray-200">
                          <button
                            onClick={() => {
                              handleRemoveCart(product);
                            }}
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-1 rounded-lg border bg-white p-2 shadow-md md:mt-0 md:w-full">
              <div className="flex justify-between pt-2">
                <div className="flex items-center justify-center">
                  <ReactWhatsapp number="+5585991409286" message={finalMessage}>
                    <div className="p-2 rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600 inline-flex items-center justify-center">
                      <span className="md:text-base text-sm text-xs  mr-5">
                        Finalizar a compra
                      </span>
                      <ion-icon name="logo-whatsapp"></ion-icon>
                    </div>
                  </ReactWhatsapp>

                  <div className="px-4">
                    <div className="relative pb-2 z-0 group">
                      <input
                        type="text"
                        className="block font-semibold py-2.5 px-0 w-full text-sm dark:text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-800 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        value={seller}
                        onChange={(e) => setSeller(e.target.value)}
                        required
                      />
                      <label
                        htmlFor="floating_email"
                        class="absolute text-sm text-gray-800 dark:text-gray-900 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Nome do Vendedor
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex-row pt-2">
                  <p className="md:text-base text-sm text-xs text-black text-lg font-bold pr-0 md:pr-10">
                    Total {formatCurrency(finalvalue)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartCheckout;
